img {
  max-width: 100%;
  height: auto;
}


ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.p__relative {
  position: relative;
}
.d__block {
  display: block;
}
.d__flex {
  display: flex;
}
.d__iblock {
  display: inline-block;
}
.jc__center {
  justify-content: center;
}
.jc__between {
  justify-content: space-between;
}
.wrap {
  flex-wrap: wrap;
}
.ai__center {
  align-items: center;
}
.ai__top {
  align-items: flex-start;
}
.ai__bottom {
  align-items: flex-end;
}
.d__grid {
  display: grid;
}
.w__50 {
  width: 50%;
}
.w__100 {
  width: 100%;
}
.t__center {
  text-align: center;
}
.t__left {
  text-align: left;
}
.t__right {
  text-align: right;
}
.mnFullwidth{
  min-width: 100vw;
}
.mbHide{
  @media screen and (max-width:1030px) {
    display: none;
  }
}
.mbShow{
  display: none;
  @media screen and (max-width:1030px) {
    display: block;
  }
}