img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mbNone {
  @media screen and (max-width: 1030px) {
    display: none;
  }
}
.mnFullwidth {
  min-width: 100vw;
}

.container {
  position: relative;
  width: 100%;
  padding: 0 30px;
  // @media screen and (max-width: 1150px) {
  //   max-width: 940px;
  // }
  // @media screen and (max-width: 940px) {
  //   max-width: 100%;
  //   padding: 0 30px;
  // }
  @media screen and (max-width: 768px) {
    padding: 0 25px;
  }
}

.btnPrimary {
  position: relative;
  width: 145px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  color: transparent;
  overflow: hidden;
  background: #000000;
  border-radius: 23px;
  // transition: 0.3s ease-in-out;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    // background: #1949FF;
    // color: #000000;
  }
  &::before {
    position: absolute;
    content: attr(data-title);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) skewY(0);
    z-index: 1;
    color: #ffffff;
    display: flex;
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &::after {
    display: flex;
    align-items: center;
    height: 100%;
    background: #1949FF;
    color: #000000;
    content: attr(data-title);
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(105%);
    width: 100%;
    transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:hover {
    border-color: #1949FF;
    &::before {
      transform: translateY(100%);
    }
    &::after {
      transform: translateY(0);
    }
  }
}

.btntrans {
  position: relative;
  padding: 10px 60px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  color: transparent;
  background: transparent;
  border-radius: 23px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 40px;
  &::before {
    position: absolute;
    content: attr(data-title);
    width: max-content;
    height: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) skewY(0);
    z-index: 1;
    color: #000000;
    display: flex;
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &::after {
    display: flex;
    align-items: center;
    height: 100%;
    background: #1949FF;
    color: #000000;
    content: attr(data-title);
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(105%);
    width: 100%;
    transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:hover {
    border-color: #1949FF;
    &::before {
      transform: translateY(100%);
    }
    &::after {
      transform: translateY(0);
    }
  }
  &.bwhite {
    border: 1px solid #ffffff;
    color: transparent;
  }
  &.bblack {
    border: 1px solid #000000;
    color: transparent;
    &::before {
      color: #000000;
    }
    &::after {
      background: #000000;
      color: #ffffff;
    }
  }
}

.link {
  position: relative;
  color: transparent !important;
  display: inline-block;
  overflow: hidden;
  height: 25px;
  &::before {
    position: absolute;
    content: attr(data-title);
    width: max-content;
    height: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) skewY(0);
    z-index: 1;
    color: #000;
    display: flex;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    @media screen and (max-width: 940px) {
      color: #ffffff;
    }
  }
  &::after {
    position: absolute;
    content: attr(data-title);
    width: max-content;
    height: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 35%) skewY(-15deg);
    z-index: 1;
    color: #000;
    display: flex;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    @media screen and (max-width: 940px) {
      color: #ffffff;
    }
  }
  &:hover {
    &::before {
      transform: translate(-50%, -120%) skew(15deg);
    }
    &::after {
      transform: translate(-50%, -50%) skew(0);
    }
  }
}

.sectionTitle {
  position: relative;
  display: flex;
  align-items: center;
  &__shape {
    opacity: 0;
    img {
      width: 95px;
      @media screen and (max-width: 575px) {
        width: 60px;
      }
    }
    margin-right: 18px;
  }
  &__heading {
    overflow: hidden;
    h4 {
      font-weight: 700;
      font-size: 22px;
      line-height: 126px;
      letter-spacing: 0.22em;
      color: #ffffff;
      text-transform: uppercase;
      .char {
        opacity: 0;
        display: inline-block;
        transform: translateY(40px);
      }
      @media screen and (max-width: 395px) {
        font-size: 18px;
      }
      @media screen and (max-width: 350px) {
        font-size: 16px;
      }
    }
  }
}

.right_col {
  position: relative;
  max-width: 700px;
  margin-left: auto;
  @media screen and (max-width: 1025px) {
    max-width: 100%;
    margin-left: 0;
  }
  p {
    max-width: 670px;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #000000;
    @media screen and (max-width: 575px) {
      line-height: 26px;
    }
  }
}
